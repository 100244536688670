@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Global Styles */
  body {
    @apply overflow-x-hidden font-body text-lg;

    /* Add padding to top/body of page while printing. */
    @media print {
      @apply py-4;
    }
  }

  main {
    @apply min-h-screen;
  }

  main:has(article > *:only-child) {
    @apply min-h-fit;
  }

  /* Body Heading Styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-6 font-primary font-semibold;
  }

  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply mb-4 text-2xl;
  }

  h4 {
    @apply mb-4 text-base;
  }

  h5 {
    @apply mb-4 text-sm;
  }

  h6 {
    @apply mb-4 text-xs;
  }

  /* Rich Text Styles */
  p,
  ul,
  ol {
    @apply mb-4;
  }

  a {
    &:hover,
    &:focus {
      @apply underline;
    }
  }

  b,
  strong {
    @apply font-bold;
  }

  sup {
    &[class='trademark-™'] {
      @apply -top-[0.28em];
    }

    &[class='trademark-℠'] {
      @apply text-[60%];
    }

    &[class='trademark-®'] {
      @apply -top-[1.07em] text-[45%];
    }
  }
}

/* Write you own custom classes here if necessary */

/* Gutenberg color classes */
.has-black-color {
  @apply text-black;
}

.has-gray-color {
  @apply text-gray-400;
}

.has-green-color {
  @apply text-green-base;
}

.has-gray-50-color {
  @apply text-gray-50;
}

.has-gray-100-color {
  @apply text-gray-100;
}

.has-gray-200-color {
  @apply text-gray-200;
}

.has-gray-300-color {
  @apply text-gray-300;
}

.has-gray-400-color {
  @apply text-gray-400;
}

.has-gray-500-color {
  @apply text-gray-500;
}

.has-gray-600-color {
  @apply text-gray-600;
}

.has-gray-700-color {
  @apply text-gray-700;
}

.has-gray-800-color {
  @apply text-gray-800;
}

.has-red-color {
  @apply text-red-base;
}

.has-teal-color {
  @apply text-teal;
}

.has-white-color {
  @apply text-white;
}

.has-yellow-color {
  @apply text-yellow;
}

/* PhotoSwipe classes */
.pswp:not(.pswp--zoom-allowed) {
  & img {
    @apply cursor-default;
  }

  & .pswp__button--zoom {
    @apply invisible;
  }
}

.pswp__top-bar {
  @apply bg-black;
}

.pswp__links {
  @apply fixed z-[11] ml-4 flex max-w-[calc(100vw-66px)] flex-nowrap gap-4 overflow-auto md:max-w-[calc(100vw-126px)];

  &.pswp__links--hidden {
    @apply hidden;
  }
}

.pswp img {
  @apply object-cover sm:object-contain;
}

.pswp__button {
  @apply overflow-visible whitespace-nowrap !opacity-100;
}

.pswp__button--view-all,
.pswp__button--category {
  @apply !w-full text-sm text-gray-500 ![overflow:unset] xs:!w-fit;

  &.active {
    @apply text-white;
  }
}

.pswp__button.pswp__button--zoom {
  @apply invisible md:visible;
}

.pswp__button--arrow {
  @apply !hidden sm:!block;

  &:empty {
    @apply !hidden;
  }
}

.pswp__preloader {
  @apply hidden;
}

.pswp__counter {
  @apply mr-auto;
}

/* Transition classes */
*[data-inview] {
  @apply lg:not-sr-only lg:transition lg:ease-out lg:motion-reduce:transition-none;
}

/* Anchor links scroll margin */
[id] {
  @apply scroll-mt-24 md:scroll-mt-32;
}
